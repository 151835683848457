<template>
  <section class="feed">
    <div class="feed__item" v-for="(item, index) in feed" :key="index">
      <div class="feed__bullet">
        <span></span>
      </div>
      <div class="feed__description" v-html="feedContent(item)">
        
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Feed",
  props: {
    feed: Array,
  },
  methods: {
    feedContent(item) {
      return this.$t("productPage.feedContent",{name: item.name, amount: item.input, date: item.date})
      //<strong>{name}</strong> aportó <strong>{amount} €</strong> el {date}
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <section class="pad-t-0">
    <div class="bb-container">
      <no-ssr>
        <portal-target name="alerts_ownerlist"></portal-target>
      </no-ssr>

      <div class="back">
        <div class="back__back">
          <a @click.prevent="$router.back()">
            <div class="back__name">
              <img
                src="../../assets/img/ico/ico-arrow-back.svg"
                loading="lazy"
                alt="Volver"
                class="back__arrow"
              />
            </div>
          </a>
        </div>
      </div>

      <div class="g-edit" v-show="loaded">
        <div class="g-edit__header">
          <h1 class="g-edit__title">{{ $t("mylist.preview") }}</h1>
        </div>
        <div class="g-edit__cols">
          <div class="g-edit__left">
            <h3 class="g-edit__head">{{ $t("productPage.productData") }}</h3>
            <div class="add-free-picture">
              <div class="add-free-picture__musthave" v-if="mustHave">
                <i class="uil uil-heart"></i> {{ $t("productPage.mustHave") }}
              </div>
              <div class="add-free-picture__items add-free-picture__items--slide">
                <no-ssr>
                  <VueSlickCarousel
                    v-if="showSlider"
                    v-bind="settings"
                    ref="carousel"
                    :key="$route.fullPath"
                  >
                    <div v-for="(image, index) in images" :key="index">
                      <div
                        class="add-free-picture__item"
                        :class="{
                          selected: selected,
                        }"
                      >
                        <div class="add-free-picture__overlay"></div>
                        <img
                          :src="image"
                          loading="lazy"
                          width="100%"
                          height="auto"
                          class="add-free-picture__img"
                          alt=""
                        />
                      </div>
                    </div>
                    <template #prevArrow="arrowOption">
                      <div class="slick-arrow--back">
                        {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                        <img src="../../assets/img/ico/ico-arrow-slick-left.svg" alt="" />
                      </div>
                    </template>
                    <template #nextArrow="arrowOption">
                      <div class="slick-arrow--next">
                        {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                        <img src="../../assets/img/ico/ico-arrow-slick-left.svg" alt="" />
                      </div>
                    </template>
                  </VueSlickCarousel>
                </no-ssr>
              </div>
            </div>
          </div>

          <div class="g-edit__content">
            <h3 class="g-edit__head">
              {{ $t("productPage.productData") }}

              <button
                class="button button--outline-dark button--sm button--ico"
                @click.prevent="editProduct"
              >
                <img src="../../assets/img/ico/ico-edit.svg" alt="" />
                {{ $t("generic.edit") }}
              </button>
            </h3>
            <section class="vista-data">
              <div class="vista-data__items">
                <div class="vista-data__item">
                  <div class="vista-data__label">{{ $t("generic.name") }}</div>
                  <div class="vista-data__output">
                    {{ name }}
                  </div>
                </div>
                <div class="vista-data__item">
                  <div class="vista-data__label">URL</div>
                  <div class="vista-data__output">
                    <div class="vista-data-url">
                      <span class="vista-data-url__url">
                        {{ url }}
                      </span>
                      <a
                        :href="(product.isFree || product.retailerName != 'HelloBB') ? ('/link?d=' + encodeURIComponent(product.url)): url"
                        target="_blank"
                        class="vista-data-url__link"
                        ><i class="uil uil-external-link-alt"></i
                      ></a>
                    </div>
                  </div>
                </div>
                <div v-if="!product.options" class="vista-data__item">
                  <div class="vista-data__label">{{ $t("generic.price") }}</div>
                  <div class="vista-data__output">
                    {{ $utils.formatMoney(price, currency) }}
                  </div>
                </div>
                <div class="vista-data__item">
                  <div class="vista-data__label">{{ $t("generic.category") }}</div>
                  <div class="vista-data__output">
                    {{ productCategoryName }}
                  </div>
                </div>
                <div class="vista-data__item" v-if="product.selectedVariantText">
                  <div class="vista-data__label vista-data__label--top">{{ $t("productPage.options") }}</div>
                  <div class="vista-data__output">
                    <div class="vista-data-estado">
                      <div class="vista-data-estado__left">
                        {{ product.selectedVariantText }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vista-data__item" v-if="userComments">
                  <div class="vista-data__label vista-data__label--top">{{ $t("productPage.comments") }}</div>
                  <div class="vista-data__output">
                    {{ userComments }}
                  </div>
                </div>
                <div class="vista-data__item" v-if="!contributionInProcess">
                  <div class="vista-data__label vista-data__label--top">{{ $t("productPage.status") }}</div>
                  <div class="vista-data__output">
                    <div class="vista-data-estado">
                      <div class="vista-data-estado__left">
                        <span v-if="parseInt(product.status) === 1"> {{ $t("generic.available") }} </span>
                        <span v-else-if="parseInt(product.status) === 2"> {{ $t("generic.purchased") }} </span>
                        <span v-else-if="parseInt(product.status) === 3">
                          {{ $t("generic.reserved") }}
                        </span>
                      </div>
                      <div class="vista-data-estado__right">
                        <!-- Descubre ///////  -->
                        <div class="edit-descubre" v-if="product.status === REGALADO">
                          <template v-if="!this.product.ownerFlaggedItemAsBought">
                            <template v-if="knownPerson">
                              <template v-if="product.status === this.REGALADO">
                                <a
                                  class="edit-descubre__action"
                                  @click="discover"
                                  v-if="discoverState"
                                >
                                  {{ $t("productPage.whoPurchasedIt") }}
                                  <i class="uil uil-angle-down"></i>
                                </a>
                                <a class="edit-descubre__action" @click="hide()" v-else>
                                  {{ $t("productPage.whoPurchasedIt") }}
                                  <i class="uil uil-angle-up"></i>
                                </a>
                              </template>

                              <span class="edit-descubre__output" v-if="!discoverState">
                                <strong>{{ $t("productPage.purchasedBy") }}</strong>
                                {{ buyerName }}
                              </span>
                            </template>
                            <template v-else>
                              {{ $t("productPage.purchasedByNoData") }}
                            </template>
                          </template>
                          <template v-else> {{ $t("productPage.purchasedByYou") }} </template>
                        </div>

                        <div class="edit-descubre" v-if="product.status === RESERVADO">
                          <a
                            class="edit-descubre__action"
                            @click="discover"
                            v-if="discoverState"
                          >
                           {{ $t("productPage.whoReservedIt") }}  <i class="uil uil-angle-down"></i>
                          </a>
                          <a class="edit-descubre__action" @click="hide()" v-else>
                            {{ $t("productPage.whoReservedIt") }} <i class="uil uil-angle-up"></i>
                          </a>

                          <span class="edit-descubre__output" v-if="!discoverState">
                            <strong>{{ $t("productPage.reservedBy") }} </strong> {{ bookerNameText }}
                          </span>
                        </div>
                        <!-- Descubre ///////  -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vista-data__item" v-else>
                  <div class="vista-data__label">{{ $t("productPage.status") }}</div>
                  <div class="vista-data__output">
                    <div class="vista-aportado">
                      <span class="vista-aportado__total">
                        {{ $t("productPage.amountInContributions") }} <strong>{{ $utils.formatMoney(product.contributionAmount) }}</strong>
                      </span>
                      <span
                        class="vista-aportado__actions"
                        @click="showFeed = !showFeed"
                        :class="showFeed ? 'vista-aportado__actions--open' : ''"
                      >
                        {{ $t("productPage.seeContributions") }} <i class="uil uil-angle-down"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="vista-data__item" v-if="showFeed">
                  <div class="vista-data__label"></div>
                  <div class="vista-data__output">
                    <Feed :feed="aportaciones"></Feed>
                  </div>
                </div>
              </div>
            </section>

            <div class="g-edit__actions">
              <edit-product-delete :listId="user.listId"></edit-product-delete>
            </div>

            <div class="g-edit__shop-now">
              <!--  Necessari v-show i no v-if per mostrar el toggle de shopify-->
              <div v-show="product.shopifyId" class="vista-ishbb-shop">
                <h2 class="vista-ishbb-shop__title">{{ $t("productPage.doYouWantToBuyIt") }}</h2>
                <div class="vista-ishbb-shop__content">
                  Cómpralo directamente en HelloBB por
                  <strong> {{ $utils.formatMoney(price) }}</strong>
                </div>
                <div class="vista-ishbb-shop__actions" v-if="!isApp">
                  <shopify-add-to-cart-button
                    v-if="productIsReady"
                    :product="product"
                    :product-from-owner-list="true"
                    @opening="sendMixPanelInfo()"
                  ></shopify-add-to-cart-button>
                </div>
                <div v-else>
                  <a
                    @click="openCheckout"
                    :href="product.checkoutUrl"
                    class="button button--block button--ico button--primary"
                  >
                    {{ $t("productPage.buyNow") }}
                  </a>
                </div>
              </div>
              <div v-if="!product.shopifyId" class="vista-ishbb-shop">
                <h2 class="vista-ishbb-shop__title">{{ $t("productPage.doYouWantToBuyIt") }}</h2>
              </div>

              <div class="regalo-shops" v-if="moreVendorsBesidesHelloBB">
                <h5 class="regalo-shops__title">
                  <template v-if="product.shopifyId"
                    ><span v-show="product.productRetailers.length > 0">
                      {{ $t("productPage.youCanAlsoFindItIn") }}
                    </span>
                  </template>
                  <template v-else>{{ $t("productPage.itsSoldIn") }}</template>
                </h5>
                <div class="regalo-shops__items">
                  <label
                    style="cursor: pointer"
                    class="regalo-shops__item"
                    v-for="(retailer, index) in productRetailers"
                    :key="index"
                  >
                    <div class="regalo-shops__shop">
                      <img
                        :src="$getLogoRetalier(retailer.name, retailer, product)"
                        width="30"
                        height="30"
                        alt
                        class=""
                      />
                      <span class="">{{ retailer.name }}</span>
                    </div>
                    <div class="regalo-shops__price regalo-shops__price--catalog">
                      {{ $utils.formatMoney(retailer.price, currency) }}
                    </div>
                    <div class="regalo-shops__button">
                      <a
                        :href="'/link?d=' + encodeURIComponent(retailer.url)"
                        class="button button--ico button--sm"
                        :class="
                          product.shopifyId ? 'button--outline-dark' : 'button--primary'
                        "
                        :target="target"
                      >
                        <i class="uil uil-external-link-alt"></i>{{ $t("generic.buy") }}
                      </a>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <alert v-if="showAlertLocal" />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import EditProductDelete from "./components/EditProductDelete";
import vClickOutside from "v-click-outside";
import NoSSR from "vue-no-ssr";
import OwnerListAlert from "./components/OwnerListAlert";
import VueSlickCarousel from "vue-slick-carousel";
import { VTooltip } from "floating-vue";
import ShopifyAddToCartButton from "../shared/ShopifyAddToCartButton";
import { $formatUrlhelloBB } from "../../plugins/string";
import Feed from "../../components/ui/progress/Feed.vue";
import CanGetCategories from "../../Mixins/CanGetCategories";

export default {
  name: "ListProductPageComprar",
  metaInfo() {
    return {
      title: this.getTitle(),
      meta: [
        {
          name: "description",
          content: this.getDescription(),
        },
        { name: "referrer", content: "no-referrer-when-downgrade" },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: this.getTitle() },
        { name: "twitter:description", content: this.getDescription() },
        { name: "twitter:image", content: "https://www.hellobb.net/hbb-card.jpg" },
        { name: "twitter:url", content: this.currentUrl() },
        { name: "twitter:label1", content: "Written by" },
        { name: "twitter:data1", content: "HelloBB" },
        { name: "twitter:label2", content: "HelloBB" },
        { name: "twitter:data2", content: this.getTitle() },

        { property: "og:title", content: this.getTitle() },
        { property: "og:site_name", content: "HelloBB" },
        { property: "og:type", content: "article" },
        { property: "og:image", content: this.photoUrl },
        { property: "og:locale", content: "es_ES" },
        //{ property: "og:image:width", content: "1280" },
        //{ property: "og:image:height", content: "775" },
        { property: "og:description", content: this.getDescription() },
        { property: "og:url", content: this.currentUrl() },
        // { property: "article:published_time", content: this.post.published_at },
        // { property: "article:modified_time", content: this.post.updated_at },
        // { property: "article:tag", content: this.post.primary_tag?.name },
        {
          property: "article:publisher",
          content: "https://www.facebook.com/holahelloBB",
        },
      ],
      link: [
        {
          rel: "canonical",
          href:
            "https://www.hellobb.net" +
            (this.product.metaInfo?.title
              ? "/catalogo/" +
                $formatUrlhelloBB(this.product.metaInfo?.title) +
                "/" +
                this.product.id
              : this.$route.path),
        },
      ],
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org/",
            "@type": "Product",
            name: this.product.name,
            image: this.getImages(),
            description: this.product?.manufacturerDescription?.replace(/<[^>]*>?/gm, ""),
            brand: {
              "@type": "Brand",
              name: this.product.brand,
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: this.product.rating,
              ratingCount:
                parseInt(this.product.ratingCount) !== 0 ? this.product.ratingCount : 0,
            },
            offers: {
              "@type": "AggregateOffer",
              offerCount: this.product.productRetailers?.length,
              lowPrice: this.product.minPrice,
              highPrice: this.product.maxPrice,
              priceCurrency: "EUR",
            },
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: this.breadcrumbArray,
          },
        },
      ],
    };
  },
  mixins: [
    CanGetCategories,
  ],
  components: {
    EditProductDelete,
    "no-ssr": NoSSR,
    alert: OwnerListAlert,
    VueSlickCarousel,
    ShopifyAddToCartButton,
    Feed,
  },
  data() {
    return {
      productIsReady: false,
      showSlider: false,
      productPhotos: [],
      imageUrl: "",
      discoverState: true,
      name: "",
      nameDirty: false,
      url: "",
      urlDirty: false,
      price: "",
      priceDirty: false,
      userComments: "",
      selectedCategory: null,
      state: "",
      saving: false,
      config: {
        handler: this.closeCategories,
        events: ["dblclick", "click"],
      },
      toggleCategories: false,
      loaded: false,
      checkedRetailer: null,
      showFeed: false,
      aportaciones: [],
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
    tooltip: VTooltip,
  },
  computed: {
    ...mapGetters({
      user: "user",
      product: "ownerListProduct",
      categories: "categories",
      showAlertKey: "showAlertKey",
      showAlert: "showAlert",
      selectedOptions: "selectedOptions",
      currency: "loggedInUserCurrency",
    }),
    selectedOptionsString() {
      let selectedOptions = "";
      this.selectedOptions.forEach((selectedOption) => {
        if (selectedOptions !== "") {
          selectedOptions =
            selectedOptions + ", " + selectedOption.name + " : " + selectedOption.value;
        } else {
          selectedOptions = selectedOption.name + " : " + selectedOption.value;
        }
      });
      return selectedOptions;
    },
    selected() {
      return this.imageUrl === this.image && this.product?.isFree;
    },
    settings() {
      return {
        draggable: false,
        centerMode: false,
        arrows: true,
        infinite: true,
      };
    },
    images() {
      return this.$utils.removeDuplicates([...this.productPhotos]);
    },
    isApp() {
      return this.$store.state.cookies.sourceApp;
    },
    target() {
      if (this.$store.state.cookies.sourceApp) {
        return "_self";
      }
      return "_blank";
    },
    disabled() {
      return !(this.name !== "" && this.url !== "" && this.price !== "");
    },
    showAlertLocal() {
      return this.showAlertKey === this.key && this.showAlert;
    },
    key() {
      return "edit_product_page";
    },
    categoryLabel() {
      const category = this.categories.find((category) => category.id === this.selectedCategory);
      let translatedName = "";
      const categoryId = category?.id;
      if (categoryId) {
        translatedName = this.getTranslatedCategory(categoryId);
      }
      return translatedName || category?.name || "";
    },
    buyerName() {
      return this.product.purchaseInfo?.buyerName;
    },
    bookerNameText() {
      return this.product.reservation?.name + "(" + this.product.reservation?.email + ")";
    },
    knownPerson() {
      if (!this.product.purchaseInfo) return false;
      return (
        this.product.purchaseInfo?.buyerName !== "" ||
        this.product.purchaseInfo?.buyerName !== null
      );
    },
    nameHasError() {
      return this.name === "" && this.nameDirty;
    },
    priceHasError() {
      return this.price === "" && this.priceDirty;
    },
    urlHasError() {
      return this.url === "" && this.urlDirty;
    },
    retailerLink() {
      if (!this.product.isFree) return this.checkedRetailer?.url;
      return this.product.url;
    },
    productRetailersName() {
      return this.product.productRetailers?.map(function (item) {
        return item["name"];
      });
    },
    SoldByHelloBB() {
      if (this.productRetailersName?.includes("HelloBB")) {
        return "Active";
      }
      return "No";
    },
    mustHave() {
      return this.product.mustHave;
    },
    productRetailers() {
      if (this.$route.params.productType === "free") {
        return [
          {
            name: this.product.retailerName,
            price: this.product.price,
            url: this.product.url,
          },
        ];
      }
      return this.product.productRetailers?.filter(function (u) {
        return u.name !== "HelloBB";
      });
    },
    moreVendorsBesidesHelloBB() {
      return !(this.product.shopifyId && this.product.productRetailers?.length === 1);
    },
    contributionInProcess() {
      return this.product.contributionAmount > 0 && this.product.status === 1;
    },
    productCategoryName() {
      return this.getTranslatedCategory(this.product.mainCategoryId) || this.product.mainCategory;
    },
  },
  watch: {
    product: {
      handler(newProduct) {
        if (newProduct?.productRetailers)
          this.checkedRetailer = newProduct?.productRetailers[0];
      },
      immediate: true,
    },
  },
  methods: {
    getImages() {
      if (this.product?.shopifyHandle) {
        return this.product?.photos;
      }
      return this.product?.photos?.map(function (item) {
        return "https://images.hellobb.net/" + item["path"];
      });
    },
    photoPath() {
      if (this.product?.photos && this.product?.photos?.length > 0)
        return this.product?.photos[0].path;
      else return null;
    },
    photoUrl() {
      return this.photoPath || "";
    },
    currentUrl() {
      return (
        process.env.APP_URL.substring(0, process.env.APP_URL.length - 1) +
        this.$route.path
      );
    },
    getTitle() {
      return (
        this.$cutText(
          this.product.metaInfo?.title ? this.product.metaInfo.title : this.product.name,
          70,
          "..."
        ) + " | HelloBB"
      );
    },
    getDescription() {
      return this.$cutText(
        this.product.metaInfo?.description
          ? this.product.metaInfo?.description
          : this.product.manufacturerDescription,
        160,
        "..."
      );
    },
    async editProduct() {
      await this.$router.push({
        name: "owner-list-edit-product",
        params: {
          productType: this.product.isFree ? "free" : "catalog",
          id: this.product.isFree ? this.product.id : this.product.listProductId,
        },
      });
    },
    async onMustHaveToggled() {
      await this.getProduct();
    },
    applyCategory(category) {
      this.selectedCategory = category.id;
      this.toggleCategories = false;
    },
    closeCategories() {
      this.toggleCategories = false;
    },
    nameBlur() {
      this.nameDirty = true;
    },
    urlBlur() {
      this.urlDirty = true;
    },
    priceBlur() {
      this.priceDirty = true;
    },
    discover() {
      this.discoverState = false;
    },
    hide() {
      this.discoverState = true;
    },
    getProductFromServer() {
      return this.getProduct(true);
    },
    async getProduct(server = false) {
      let payload = {
        // En els productes de catàleg el productId no és l'id de producte si l'id del producte a la llista pq un mateix producte pot estar amb diferents variants/opcions però mateix id
        productId: this.$route.params.id,
      };

      if (server) {
        payload.server = server;
      }
      if (this.$route.params.productType === "catalog") {
        await this.$store.dispatch("getListProduct", payload);
      }
      if (this.$route.params.productType === "free") {
        payload.listId = this.user.listId;
        await this.$store.dispatch("getOwnerListFreeProduct", payload);
      }
    },
    async proceedGetProduct() {
      this.$store.commit("setOwnerListProduct", {});
      try {
        await this.getProduct();
      } catch (error) {
        console.log(error);
        if (error.response?.status === 404) {
          await this.$router.push({ name: "notfound" });
        }
        throw error;
      }
    },
    async updateUserComments() {
      const body = {
        title: this.userComments,
      };
      await this.$store.dispatch("updateProductComment", { product: this.product, body });
    },
    async updateFreeProductData() {
      const body = {
        name: this.name,
        info: this.userComments,
        price: parseFloat(this.price.replace(",", ".")),
        url: this.url,
        mainCategoryId: this.selectedCategory,
      };
      await this.$store.dispatch("updateFreeProductData", {
        listId: this.user.listId,
        product: this.product,
        body,
      });
    },
    async save() {
      this.saving = true;
      if (this.product.isFree) {
        await this.updateFreeProductData();
      } else {
        await this.updateUserComments();
      }
      this.saving = false;
      window.localStorage.setItem(
        "alertMessage",
        this.$t("generic.saveMessage")
      );
      //if(!this.$utils.isApp()) this.$router.push({ name: "owner-list" });
      //else { location.href = process.env.APP_URL + "mi-lista?source=app"; }
      this.$router.push({
        name: "owner-list",
      });
    },
    updateState() {
      this.state = this.states.find((state) => state.id === this.product.status);
      this.$refs.productStatus?.updateState();
    },
    async onRemoveBooking() {
      this.$alert.success(this.$t("productPage.successCancelReservation"), this.key);
      await this.getProduct();
      this.updateState();
    },
    async onStatusDone() {
      const oldStatus = this.product.status;
      await this.getProduct();
      const newStatus = this.product.status;
      this.updateState();
      if (newStatus === oldStatus) return;
      if (oldStatus === this.PENDIENTE) {
        this.$alert.success(
          this.$t("productPage.successFromAvailableToGifted"),
          this.key
        );
      }
      if (oldStatus === this.REGALADO) {
        this.$alert.success(
          this.$t("productPage.successFromGiftedToAvailable"),
          this.key
        );
      }
      if (oldStatus === this.RESERVADO) {
        this.$alert.success(
          this.$t("productPage.successFromReservedToGifted"),
          this.key
        );
      }
    },
    sendMixPanelInfo() {
      window.mixpanel.identify(this.user?.loggedUserId);
      window.mixpanel.track("Comprar", {
        "Object name": this.product.name,
        "Selected Vendor": "HelloBB",
        "Retailer name": "HelloBB",
        "Object price": this.product.price,
        "List URL": "https://www.hellobb.net/guest/" + this.user.listId,
        "Buyer role": "Propietari",
        Src: "fitxa llista propietari",
        "Shopify BuyButton": "Si",
        "Sold by HelloBB": this.SoldByHelloBB,
        "Item Retailers": this.productRetailersName,
      });
    },
    openCheckout() {
      window.mixpanel.track("Tramitar comanda", {
        "Total amount": this.product.price,
        "Total items": 1,
        "Cart ID": "no-cart-id",
        Role: "Propietari",
      });
      window.open(this.product.checkoutUrl, "_blank");
    },
    viewProduct() {
      window.open(this.product.isFree ? this.product.url : this.url, "_self");
    },
  },
  async serverPrefetch() {
    return await this.getProductFromServer();
  },
  created() {
    this.PENDIENTE = 1;
    this.REGALADO = 2;
    this.RESERVADO = 3;
    this.states = [
      {
        id: this.PENDIENTE,
        name: "Pendiente",
      },
      {
        id: this.REGALADO,
        name: "Regalado",
      },
      {
        id: this.RESERVADO,
        name: "Reservado",
      },
    ];
  },
  async mounted() {
    if (!this.product) {
      return await this.proceedGetProduct();
    }
    const type = this.product.isFree ? "free" : "catalog";

    if (type === "free") {
      if (
        parseInt(this.product.id) !== parseInt(this.$route.params.id) ||
        type !== this.$route.params.productType
      ) {
        await this.proceedGetProduct();
      } else {
        if (!this.product.server) {
          await this.proceedGetProduct();
        }
      }
    } else {
      if (
        parseInt(this.product.listProductId) !== parseInt(this.$route.params.id) ||
        type !== this.$route.params.productType
      ) {
        await this.proceedGetProduct();
      } else {
        if (!this.product.server) {
          await this.proceedGetProduct();
        }
      }
    }
    if (!this.product.id) return;

    this.productIsReady = true;

    this.aportaciones = this.product.contributions?.map((contribution) => {
      return {
        name: contribution.name,
        input: this.$utils.formatMoney(contribution.amount),
        date: contribution.createdAtString,
      };
    });

    this.imageUrl = this.$getImgUrl(
      this.product.photos?.find((photo) => photo.main)?.path
    );
    this.productPhotos = this.product.photos?.map((photo) => this.$getImgUrl(photo.path));
    if (this.images.length > 0) {
      import("vue-slick-carousel/dist/vue-slick-carousel.css");
      import("vue-slick-carousel/dist/vue-slick-carousel-theme.css");
      this.showSlider = true;
    }

    this.name = this.product.name;
    this.url = this.product.isFree
      ? this.product.originalUrl
      : !this.product.metaInfo?.title
      ? "https://www.hellobb.net/" +
        this.$formatUrlhelloBB(this.product.brand || "") +
        "/" +
        this.product.id
      : "https://www.hellobb.net/catalogo/" +
        this.$formatUrlhelloBB(this.product.metaInfo?.title) +
        "/" +
        this.product.id;
    this.userComments = this.product.userComments;
    this.price = this.product.price.toString();

    this.loaded = true;

    this.updateState();

    await this.$store.dispatch("getCategories");
    this.selectedCategory = this.product.mainCategoryId;
  },
};
</script>

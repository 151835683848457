<template>
  <!-- 
  En la página de producte els botons ocupen el fullwith de la card usem .regalo-actions__actions
  En la página de llista regalador hi ha dos botons en linia: compar ya i añadir a carrito, usem la clase .list-card-add__actions 
  -->
  <span
    :class="[
      $route.name == 'product-new' ? 'regalo-actions__actions' : 'list-card-add__actions',
    ]"
  >
    <span class="" v-if="$route.name == 'catalogue'">ok</span>
    <product-added-to-cart-modal
      :show="showProductAddedToCart"
      @cancel="cancelProductAddedToCart()"
    />
    <loading-button v-if="loading" :class="'button-loading--sm'" />
    <template v-else>
      <button
        v-if="availableForSale && comprarYa"
        class="button button--ico button--primary button--block"
        @click.stop="openCheckoutUrl()"
      >
        <i class="uil"></i> Comprar ya
      </button>
      <button
        v-if="availableForSale && addToCartVisible"
        :class="[
          'button',
          'button--ico',
          { 'button--primary': !comprarYa, 'button--dark-outline': comprarYa },
          'button--block',
        ]"
        @click.stop="addToCart()"
      >
        <i class="uil uil-shopping-cart"></i> Añadir al carrito
      </button>
      <button
        v-else-if="!availableForSale"
        class="button button--ico button--primary button--block"
        disabled="true"
      >
        <i class="uil uil-shopping-cart"></i> Temporalmente sin estoc
      </button>
    </template>
    <loading-checkout-page-indicator
      v-if="loadingCheckoutPage"
    ></loading-checkout-page-indicator>
  </span>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "ShopifyAddToCartButton",
  components: {
    LoadingButton: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "LoadingButton" */ "../../components/LoadingButton"
      ),
    ProductAddedToCartModal: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ProductAddedToCartModal" */ "./ProductAddedToCartModal"
      ),
    LoadingCheckoutPageIndicator: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "LoadingCheckoutPageIndicator" */ "../../components/LoadingCheckoutPageIndicator"
      ),
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    productFromOwnerList: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      required: false,
    },
    comprarYa: {
      type: Boolean,
      default: false,
    },
    addToCartVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      showProductAddedToCart: false,
      loadingCheckoutPage: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedVariant: "selectedVariant",
      selectedVariantOwnerListProduct: "selectedVariantOwnerListProduct",
      selectedQuantity: "selectedQuantity",
      cart: "cart",
      isAuthenticated: "isAuthenticated",
      myList: "myList",
    }),
    availableForSale() {
      return this.variant?.availableForSale;
    },
    variant() {
      if (this.product.shopifyId) {
        let variant = this.productFromOwnerList
          ? this.selectedVariantOwnerListProduct
          : this.selectedVariant;
        if (!variant) {
          return this.product.selectedVariant;
        }
        return variant;
      }
      return null;
    },
  },
  methods: {
    cancelProductAddedToCart() {
      this.showProductAddedToCart = false;
    },
    async addToCart() {
      this.$emit("opening");
      this.loading = true;
      let response;
      try {
        // Hi ha el or (||) per fer el codi més robust. Hi ha vegades que tenim el identificador en diferents llocs segons
        // quina crida api o lloc ho hem fet (cataleg/llista propietari o regalador).
        // Hem escollit està opció en comptes de repassar totes les crides api i tenir a tot arreu els valors plens correctament
        const merchandise =
          this.product.shopifyProductVariantId || this.variant.shopifyId;
        if (this.cart) {
          response = await axios.post("/cart/cartline", {
            cart: this.cart.id,
            quantity: this.selectedQuantity,
            merchandise: merchandise,
            listProductId: this.product.listProductId,
          });
        } else {
          response = await axios.post("/cart", {
            quantity: this.selectedQuantity,
            merchandise: merchandise,
            listProductId: this.product.listProductId,
          });
        }
      } catch (error) {
        this.loading = false;
        throw error;
      }
      this.loading = false;
      this.$store.commit("setCart", response.data);
      if (this.source === "Constructor") this.showProductAddedToCart = true;
      else this.$store.commit("setToggleCart", true);

      let role;
      let variantTitle;
      //do something when url contains "guest"
      if (window.location.href.indexOf("guest") > -1) {
        role = "Regalador";
        variantTitle = this.product.selectedVariant.optionValues
          .map((option) => option.optionValue)
          .join(" / ");
      } else if (window.location.href.indexOf("mi-lista") > -1) {
        role = "Propietari";
        variantTitle = this.product.selectedVariant.optionValues
          .map((option) => option.optionValue)
          .join(" / ");
      } else {
        role = this.isAuthenticated ? "Propietari" : "Visitant";
        variantTitle = this.variant.optionValues
          .map((option) => option.optionValue)
          .join(" / ");
      }
      window.mixpanel.track("Afegir al carro", {
        "Item name": this.product.name + " - " + variantTitle,
        "Item ID": this.product.id,
        "Item amount": this.product.price,
        Quantity: this.selectedQuantity,
        "Item added date": this.product.addedAt,
        Role: role,
        "Cart ID": response.data.id,
      });
    },
    async openCheckoutUrl(bookingValidated = false) {
      // if (this.product.status == 3 && !bookingValidated) {
      //   this.$emit("confirmBooking");
      //   return;
      // }
      this.loadingCheckoutPage = true;

      var response = await axios.get(
        "/createcheckout/" +
          this.product.listProductId +
          "/" +
          this.product.shopifyProductVariantId
      );
      this.product.checkoutUrl = response.data;

      let arr = this.product.checkoutUrl.split("/");
      let chid = arr[arr.length - 1];

      window.mixpanel.identify(this.myList.userId);
      window.mixpanel.track("Comprar Ya", {
        "Object name": this.product.name,
        "Object price": this.product.price,
        "Product ID": this.product.id,
        "Buyer role": "Regalador",
      });

      await fetch(
        process.env.API + "/list/" + this.myList.id + "/draftcheckout/" + chid,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      var newWindow = window.open(this.product.checkoutUrl, "_self");
      setTimeout(() => {
        this.loadingCheckoutPage = false;
      }, 15000);

      //newWindow.onload = () => {
      //  this.loadingCheckoutPage = false;
      //};
    },
  },
};
</script>
